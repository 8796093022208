body {
  overflow-x: hidden;
}

:global(#how-it-works-hero) {
  @media (max-width: 1550px) {
    max-height: 480px;
  }

  & h2 {
    font: normal normal 910 80px/90px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 375px;
    text-align: left;

    @media (max-width: 1360px) {
      font-size: 70px;
      line-height: 80px;
    }

    @media (max-width: 1023px) {
      font-size: 48px;
      line-height: 56px;
      text-align: center;
    }

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  & p {
    font: normal normal 500 32px/44px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1023px) {
      font-size: 24px;
      line-height: 28px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* display: none; */
  /* min-height: 90vh; */
  & img {
    object-position: 80% 0%;
    height: 546px;
    max-width: 648px;

    @media (max-width: 1550px) {
      max-width: 480px;
    }
  }

  & header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 60px;
    padding: 0;
    padding-bottom: 80px;

    @media (max-width: 1550px) {
      max-height: 480px;
    }

    @media (max-width: 1023px) {
      margin: auto;
    }

    @media (max-width:768px) {
      padding-bottom: 10px;
    }
  }

  &>div {
    &:nth-child(2) {
      display: grid;
      grid-template-columns: 50% 49%;
      padding-bottom: 0;
      padding-top: 42px;

      @media (max-width: 1023px) {
        grid-template-columns: 100%;
      }

      &>div {
        width: 100%;
        margin-left: 0;
        padding: 0;

        @media (max-width: 1023px) {
          margin-left: auto;
        }
      }
    }
  }

  & :global(#hero-img) {
    max-height: 546px;

    &>div>div>div>div {
      @media (max-width: 1023px) {
        text-align: center;
      }
    }
  }
}

:global(#feature) {
  overflow: hidden;

  & h2 {
    font: normal normal 900 44px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & p {
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &>div {
    &:nth-child(2) {
      padding-bottom: 0;

      &>div {
        max-width: 1440px;
        gap: 64px;

        @media (max-width: 1600px) {
          gap: 32px;
        }

        @media (max-width: 1023px) {
          padding: 0 20px;
        }
      }
    }
  }

  & :global(#the-what) {
    flex-direction: row;
    position: relative;
    gap: 30px;
    padding: 30px 0;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }

    &:nth-child(1) {
      &>div>div>div>div {
        @media (min-width: 1600px) {
          /* position: absolute;
          top: -7%;
          left: -42%;
          width: 894px;
          height: 576px; */
        }
      }
    }
  }

  & :global(#the-why) {
    flex-direction: row-reverse;
    position: relative;
    padding: 30px 0;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }

    &>div:nth-child(1) {
      &>div>div>div {
        @media (min-width: 1600px) {
          position: absolute;
          top: -40px;
          right: -42%;
          left: unset;
          width: 894px;
          height: 576px;
        }
      }
    }
  }

  & :global(#the-how) {
    flex-direction: row;
    position: relative;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }

    &>div:nth-child(2) {
      margin-bottom: 170px;
      margin-top: 82px;

      @media (max-width: 767px) {
        margin-bottom: 48px;
        margin-top: 32px;
      }
    }

    &>div:nth-child(1) {
      &>div>div>div {
        @media (min-width: 1600px) {
          position: absolute;
          top: 0;
          left: -25%;
          width: 778px;
          height: 540px;
        }
      }
    }
  }
}

:global(#featured-local-gems) {
  & header {
    justify-content: center;
  }

  &>div {
    padding: 104px 0 70px;
  }

  & p {
    font: normal normal 700 24px/40px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & a {
    border: 3px solid #bf5c3a;
    background: #bf5c3a;
    font: normal normal bold 18px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 36px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
    justify-content: center;


    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

:global(#lets-get-this) {
  &>div {
    display: flex;
    padding: 10px 24px 36px;
    gap: 16px;
    flex-direction: row-reverse;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0 20px 36px;
    }

    &>div {
      width: 35%;
      padding: 0;
      gap: 15px;
      margin: 0;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }

  & h2 {
    font: normal normal 900 44px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & p {
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & a {
    text-align: center;
    text-decoration: underline;
    font: normal normal bold 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    background: unset;
    box-shadow: unset;
  }

  & :global(#merchants) {
    background: #c4d4d2;
    text-align: center;
    padding: 120px 0 110px;

    @media (max-width: 1500px) {
      padding: 60px 0;
    }
  }

  & :global(#shoppers) {
    background: #e1b23d 0% 0% no-repeat padding-box;
    text-align: center;
    padding: 120px 0 110px;

    @media (max-width: 1500px) {
      padding: 60px 0;
    }
  }

  & header {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 90px 103px;
    max-width: 100%;

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 1023px) {
      height: 400px;
      padding: 48px;
    }

    @media (max-width: 767px) {
      height: 300px;
      padding: 32px;
    }

    & video {
      border-radius: unset;
    }

    & h2 {
      font: normal normal 900 80px/90px Roc Grotesk;
      letter-spacing: 0px;
      color: #ffffff;
      text-align: left;
      max-width: 600px;
      z-index: 1;
      font-weight: 700;

      @media (max-width: 1023px) {
        font-size: 48px;
        line-height: 56px;
      }

      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
}